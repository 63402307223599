import {
  ExistingKeywordStatus,
  PageType,
  Search as PrismaSearch,
  SearchType,
} from '@prisma/client'

export interface Search {
  id: string
  keyword: string
  language: string
  country: string
  url: string
  accessToken?: string
  googleInsight: number
  authorityScore: number
  spamScore: number
  createdAt: Date
  updatedAt?: Date
  hasBeenOpened: boolean
  readable?: number
  totalLoadTime?: number
  wordCount?: number
  status: SearchStatus
  searchType: ContentModes
  keywordResult: SearchResult[]
  existingKeywordResult: ExistingSearchResult[]
}

enum SearchStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export interface ExistingSearchResult {
  status: ExistingKeywordStatus
  id?: string
  ctr: number
  clicks: number
  impressions: number
  position: number
  positionOld: number
  searchId: number
  keyword: string
  pageUrl: string
}

export interface SearchResult {
  id?: string
  keyword: string
  isMainKeyword: boolean
  volume: number
  competition: number
  page: number
  rankingPlace: number
  authorityScore: number
  organicResult: OrganicResult[]
}

export interface ColumnData {
  title: string
  subText: string
  tooltip: string
  data: keyof OrganicResult
}

export interface BadgeData {
  text: string
  data: string
  badge: string
  image: string
  content: string
  weaknessesCount?: number
}

export interface KeywordResultWithSingleOrganicResult {
  keyword: string
  isMainKeyword: boolean
  volume: number
  competition: number
  organicResult: OrganicResult
}

export interface SearchWithSingleResult {
  searchType: ContentModes
  id: string
  userId: string
  activeProductIds: string[]
  status: SearchStatus
  title: string | null
  keyword: string
  url: string
  difficulty: number
  authorityScore: number
  isActive: boolean
  createdAt: Date
  updatedAt: Date
  periodStart: Date | null
  periodEnd: Date | null
  language: string
  country: string
  keywordResult: KeywordResultWithSingleOrganicResult
}

export interface OrganicResult {
  url: string
  title: string
  isUserPage: boolean
  authorityScore: number
  domainsNum: number
  rankingPlace: number
  lastUpdated: Date | null
  missingWordsFromTitle: string[]
  totalLoadTime: number
  wordCount: number
  readability: number
  pageType: PageType | null
}

export interface KeywordResult {
  id?: string
  keyword: string
  isMainKeyword: boolean
  volume: number
  competition: number
  organicResult: OrganicResult[]
}

export type newSearchData = Omit<
  PrismaSearch,
  | 'id'
  | 'status'
  | 'mozDa'
  | 'mozPa'
  | 'googleInsight'
  | 'spamScore'
  | 'accessToken'
  | 'createdAt'
  | 'updatedAt'
  | 'keywordResult'
>

export enum ContentModes {
  CREATE_NEW = 'CREATE_NEW',
  UPDATE_EXISTING = 'UPDATE_EXISTING',
}

export interface columnData {
  title: string
  subText: string
  tooltip: string
  data: string
}

export interface badgeData {
  text: string
  data: string
  badge: string
  image: string
  content: string
  weaknessesCount?: number
}

export interface TooltipData {
  title: string
  info: string
  badge: string
  todo: string
}

export interface makeSearchProps {
  searchType: ContentModes
  url: string
  keyword?: string
  language: string
  country: string
}
